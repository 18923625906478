import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome for icons
import axios from 'axios';

const Dashboard = () => {

  const[StudentList,setStudentList]=useState([])
  const  getStudentlist=async ()=>{
    try {
      const getdata=await axios.get("https://satechnology.org/api/admin/getstudentlist");
      if(getdata){
        setStudentList(getdata.data.studentlist)
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(()=>{
    getStudentlist()
  },[])


  const[CourseList,setCourseList]=useState([])
  const getCourselist=async()=>{
     try {
      const getdata=await axios.get(
        "https://satechnology.org/api/admin/getcourse"
      )
      if(getdata){
        setCourseList(getdata.data.getcourse)
      }
     } catch (error) {
      console.log(error);
     }
  }
  useEffect(()=>{
    getCourselist()
  },[])


  let[Folder,setFolder]=useState([])

  const getFoldername=async ()=>{
    try {
      const getdata=await axios.get("https://satechnology.org/api/admin/getfoldername")
      if(getdata){
        setFolder(getdata.data.folderlist)
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(() => {
    getFoldername()
  }, [])
  return (
    <div>
      {/* Dashboard Content */}
      <div className="content">
        <div className="container">
          <h1>Dashboard</h1>
          <p>
            Welcome to the admin dashboard. You can manage your website's content,
            users, and settings from here.
          </p>

          {/* Example of some admin stats or widgets */}
          <div className="row">
            <div className="col-md-4">
              <div className="card text-white bg-primary mb-3">
                <div className="card-body">
                  <h5 className="card-title">Total Student</h5>
                  <p className="card-text">{StudentList?.length}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card text-white bg-success mb-3">
                <div className="card-body">
                  <h5 className="card-title">Course List</h5>
                  <p className="card-text">{CourseList?.length}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card text-white bg-danger mb-3">
                <div className="card-body">
                  <h5 className="card-title">Folder List</h5>
                  <p className="card-text">{Folder?.length}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
