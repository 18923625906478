import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome
import axios from 'axios';

const BittsDoes = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const toggleAddModal = () => setShowAddModal(!showAddModal);
  const toggleEditModal = () => setShowEditModal(!showEditModal);

  const[Image,setImage]=useState("")
  const[Title,setTitle]=useState("")
  const[Description,setDescription]=useState("")
 
  const addBitsdoes=async ()=>{
    try {
       const formData=new FormData();
       formData.append("image",Image);
       formData.append("title",Title);
       formData.append("description",Description);
   
       const config={
        url: "/addbitsdoes",
        method: "post",
        baseURL: "https://satechnology.org/api/admin",
        headers: { 
          "Content-Type": "multipart/form-data" // Correct content type for file uploads
        },
        data: formData,
      };
      let res = await axios(config);
  
      if (res.data.success) { // Correct way to access success response
        alert(res.data.success);
        toggleAddModal();
        getBitsdoes()
      }
    } catch (error) {
      alert(error.response?.data?.error || "something went wrong")
    }
  }

  const[Bits,setBits]=useState([])
  const getBitsdoes=async ()=>{
     try {
      const getdata=await axios.get(
        "https://satechnology.org/api/admin/getbitsdoes"
      )
      if(getdata){
        setBits(getdata.data.bitsdoes)
     
      }
     } catch (error) {
      console.log(error);
      
     }
  }
  useEffect(()=>{
    getBitsdoes()
  },[])

  const deleteBitsdoes=async (bitsid)=>{
    const confirmed=window.confirm("Are you sure you want to delete this banner?")
    if(confirmed){
    try {
      const deletedata = await axios.delete(
        `https://satechnology.org/api/admin/deleteBitsdoes/${bitsid}`
      );
      if (deletedata.status === 200) {
        alert(deletedata.data.success);
        getBitsdoes();
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  }
  }


  const[EditImage,setEditImage]=useState("")
  const[EditTitle,setEditTitle]=useState("")
  const[EditDescription,setEditDescription]=useState("")
  const[BitsId,setBitsId]=useState("")
 
  const editBitsdoes=async ()=>{
    try {
       const formData=new FormData();
       formData.append("image",EditImage);
       formData.append("title",EditTitle);
       formData.append("description",EditDescription);
       formData.append("id",BitsId);
   
       const config={
        url: "/editbitsdoes",
        method: "put",
        baseURL: "https://satechnology.org/api/admin",
        headers: { 
          "Content-Type": "multipart/form-data" 
        },
        data: formData,
      };
      let res = await axios(config);
  
      if (res.data.success) { 
        alert(res.data.success);
        toggleEditModal();
        getBitsdoes()
        
      }
    } catch (error) {
      alert(error.response?.data?.error || "something went wrong")
    }
  }

  return (
    <div className="container mt-2">
      <div className="display" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Bits Does </h2>
        <button type="button" className="btn btn-primary" onClick={toggleAddModal}>
          <i className="fas fa-plus"></i> Add
        </button>
      </div>

      {/* Add Modal */}
      {showAddModal && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Bits Does</h5>
                <button type="button" className="btn-close" onClick={toggleAddModal}></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                      Upload Image
                    </label>
                    <input type="file" className="form-control" 
                    onChange={(e)=>{setImage(e.target.files[0])}} />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Title  </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name" onChange={(e)=>{setTitle(e.target.value)}}/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea className="form-control" id="description" rows="3" placeholder="Enter description"
                    onChange={(e)=>{setDescription(e.target.value)}}></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary"
                onClick={addBitsdoes}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Student Table */}
      <table className="table table-bordered mt-2">
        <thead className="table-light">
          <tr>
            <th>S.No</th>
            <th>Image</th>
            <th>Title</th>
            <th>Description</th>
            <th style={{width:"125px"}}>Action</th>
          </tr>
        </thead>
        <tbody>
         {Bits?.map((item,i)=>{
          return (
            <tr>
            <td>{++i}</td>
            <td>
              <img style={{height:"150px", width:"200px"}}
                src={`https://satechnology.org/Bitsdoes/${item?.image}`}
                alt="bits" />
           </td>
            <td>{item?.title}</td>
            <td>{item?.description}</td>
            <td>
              <button className="btn btn-warning" onClick={()=>{
                toggleEditModal();
                setBitsId(item?._id)
                setEditTitle(item?.title)
                setEditDescription(item?.description)
              }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className="btn btn-danger" style={{ marginLeft: '11px' }}
              onClick={()=>{deleteBitsdoes(item?._id)}}>
                <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          )
         })}
        </tbody>
      </table>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Bits Does</h5>
                <button type="button" className="btn-close" onClick={toggleEditModal}></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="edit-image" className="form-label">
                      Upload Image
                    </label>
                    <input type="file" className="form-control"
                    onChange={(e)=>{setEditImage(e.target.files[0])}} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Title  </label>
                    <input
                      type="text"
                      className="form-control"
                      value={EditTitle}
                      onChange={(e)=>{setEditTitle(e.target.value)}}/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="edit-description" className="form-label">
                      Description
                    </label>
                    <textarea className="form-control" rows="3"
                    value={EditDescription}
                    onChange={(e)=>{setEditDescription(e.target.value)}}>
                    </textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary"
                onClick={editBitsdoes}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BittsDoes;
