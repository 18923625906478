import React, { useEffect, useState } from "react";
import "../styles/home.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import parse from "html-react-parser";

function Home() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [Banner, setBanner] = useState([]);
  const getBanner = async () => {
    try {
      const getdata = await axios.get(
        "https://satechnology.org/api/admin/getbanner"
      );
      if (getdata) {
        setBanner(getdata.data.allbanner);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBanner();
  }, []);

  const [About, setAbout] = useState([]);
  const getAbout = async () => {
    try {
      const getdata = await axios.get(
        "https://satechnology.org/api/admin/getabout"
      );
      if (getdata) {
        setAbout(getdata.data.getabout);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAbout();
  }, []);


  const[Bits,setBits]=useState([])
  const getBitsdoes=async ()=>{
     try {
      const getdata=await axios.get(
        "https://satechnology.org/api/admin/getbitsdoes"
      )
      if(getdata){
        setBits(getdata.data.bitsdoes)
     
      }
     } catch (error) {
      console.log(error);
      
     }
  }
  useEffect(()=>{
    getBitsdoes()
  },[])

  const[CourseList,setCourseList]=useState([])

  const getCourselist=async()=>{
     try {
      const getdata=await axios.get(
        "https://satechnology.org/api/admin/getcourse"
      )
      if(getdata){
        setCourseList(getdata.data.getcourse)
      }
     } catch (error) {
      console.log(error);
     }
  }

  useEffect(()=>{
    getCourselist()
  },[])
  return (
    <div>
      <div className="bannerbg">
        <div
          className="bannerText"
          data-aos="zoom-in-up"
          data-aos-duration="2000"
          data-aos-delay="2000"
        >
          {Banner?.map((item, i) => {
            return <div>{parse(item?.bannertext || "")}</div>;
          })}
             </div>
      </div>

      {About.map((item) => {
        return (
          <div className="about">
            <div className="left">
              <p>{parse(item?.aboutusdesc || "")}</p>
            </div>
            <div className="right">
              <div
                className="imgbox"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
              >
                <img
                  src={`https://satechnology.org/About/${item?.aboutusimage}`}
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="container cardSection mt-5 d-flex flex-column align-items-center">
        <h1>What does Bits Traffic do ?</h1>
        <div className="row w-100 mt-5 d-flex justify-content-around ">
          {Bits?.map((item,i)=>{
            return(
          <div className="col-md-4 d-flex justify-content-center ">
            <Card
              sx={{
                maxWidth: 300,
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
              data-aos="zoom-in-down"
              data-aos-duration="2500">
              <CardMedia
                sx={{ height: 160 }}
                image={`https://satechnology.org/Bitsdoes/${item?.image}`}
                title="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ fontFamily: "Rubik" }} >
                  {item?.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.dark",
                    textAlign: "justify",
                    fontFamily: "Rubik",
                  }} >
               {item?.description}
                </Typography>
              </CardContent>
              {/* <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions> */}
            </Card>
          </div>
            )
          })}
        </div>
      </div>

      <div className="container careerSection mt-5">
        <div className="row">
          <div className="col-md-4 ">
            <img
              src="/images/unlock.png"
              alt=""
              srcset=""
              className="unlockImg"
            />
          </div>
          <div className="col-md-8  text-start px-5 py-5">
            <h1 id="carrer">Unlock your career potential</h1>
            <p>
            Your career deserves more than just opportunities—it deserves transformation. At BitsTraffic, we don’t just prepare you for the IT industry; we empower you to lead it. With our blend of expertise, innovation, and passion for IT, we unlock the door to your limitless potential.
            </p>

<p>
  The world of IT is constantly changing, and at BitsTraffic, we equip you with the tools to stay ahead. Our innovative approach not only fosters technical mastery but also cultivates critical thinking and problem-solving abilities. By joining us, you step into a community of forward-thinking professionals dedicated to pushing the boundaries of what's possible in technology. With our hands-on experience and mentorship, we give you the power to transform your passion into a fulfilling career that stands out.
</p>

            {/* <Button
              variant="outlined"
              className="rounded-5"
              style={{ fontFamily: "Rubik" }}
              data-aos="slide-left"
              data-aos-duration="2000"
            >
              Explore
            </Button> */}
          </div>
        </div>

        <div className="row d-flex justify-content-around">
          {CourseList?.map((item,i)=>{
            return (
          <div
            className="col-md-6  d-flex justify-content-center flex-column  px-4 border-end"
            style={{ textAlign: "justify" }}
          >
            <h6><b>{item?.coursename}</b></h6>
            <p>{item?.coursedesc} </p>
          </div>
            )
          })}

        </div>
      </div>
    </div>
  );
}

export default Home;
