import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from "html-react-parser";
const AboutUs = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const toggleAddModal = () => setShowAddModal(!showAddModal);
  const toggleEditModal = () => setShowEditModal(!showEditModal);

  
  const[Aboutusimage,setAboutusimage]=useState("")
  const[Aboutusdesc,setAboutusdesc]=useState("")
 
  const addAbout = async () => {
    try {
      const formData = new FormData();
      formData.append("aboutusimage", Aboutusimage);
      formData.append("aboutusdesc", Aboutusdesc);
  
      const config = {
        url: "/addabout",
        method: "post",
        baseURL: "https://satechnology.org/api/admin",
        headers: { 
          "Content-Type": "multipart/form-data" // Correct content type for file uploads
        },
        data: formData,
      };
  
      let res = await axios(config);
  
      if (res.data.success) { // Correct way to access success response
        alert(res.data.success);
        toggleAddModal();
        getAbout();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };

  const[About,setAbout]=useState([])

  const getAbout=async()=>{
     try {
      const getdata=await axios.get(
        "https://satechnology.org/api/admin/getabout"
      )
      if(getdata){
        setAbout(getdata.data.getabout)
      }
     } catch (error) {
      console.log(error);
     }
  }

  useEffect(()=>{
    getAbout()
  },[])

  const deleteAbout = async (imageid) => {
    const confirmed=window.confirm("Are you sure you want to delete this?")
    if(confirmed){
    try {
      const deletedata = await axios.delete(
        `https://satechnology.org/api/admin/deleteabout/${imageid}`
      );
      if (deletedata.status === 200) {
        alert(deletedata.data.success);
        getAbout();
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  }
  };


  const[EditAboutusimage,setEditAboutusimage]=useState("")
  const[EditAboutusdesc,setEditAboutusdesc]=useState("")
  const[AboutId,setAboutId]=useState("")
  const editAbout = async () => {
    try {
      const formData = new FormData();
      formData.append("aboutusimage", EditAboutusimage);
      formData.append("aboutusdesc", EditAboutusdesc);
      formData.append("id", AboutId);
  
      const config = {
        url: "/editabout",
        method: "put",
        baseURL: "https://satechnology.org/api/admin",
        headers: { 
          "Content-Type": "multipart/form-data" // Correct content type for file uploads
        },
        data: formData,
      };
  
      let res = await axios(config);
  
      if (res.data.success) { 
        alert(res.data.success);
        toggleEditModal();
        getAbout();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };
  return (
    <div className="container mt-2">
      <div className="display" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>About Us</h2>
        <button type="button" className="btn btn-primary" onClick={toggleAddModal}>
          <i className="fas fa-plus"></i> Add
        </button>
      </div>

      {/* Add Modal */}
      {showAddModal && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">All About Us</h5>
                <button type="button" className="btn-close" onClick={toggleAddModal}></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                      Upload Image
                    </label>
                    <input type="file" className="form-control"
                    onChange={(e)=>{setAboutusimage(e.target.files[0])}}/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                     
                    </label>
                    <CKEditor
              editor={ClassicEditor}
              data={Aboutusdesc}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAboutusdesc(data);
                   }}
                   />
                   </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={addAbout}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Student Table */}
      <table className="table table-bordered mt-2">
        <thead className="table-light">
          <tr>
            <th>S.No</th>
            <th>Image</th>
            <th>Description</th>
            <th style={{width:"125px"}}>Action</th>
          </tr>
        </thead>
        <tbody>
         {
          About?.map((item,i)=>{
            return(
              <tr>
              <td>{++i}</td>
              <td>
                <img 
               style={{height:"150px", width:"200px"}}
               src={`https://satechnology.org/About/${item?.aboutusimage}`}
               alt="about" />
              </td>
              <td >{parse(item?.aboutusdesc || '')}</td>
              <td>
                <button className="btn btn-warning" onClick={()=>{
                  toggleEditModal();
                  setAboutId(item?._id)
                  setEditAboutusdesc(item?.aboutusdesc)
                }}>
                  <i className="fas fa-edit"></i>
                </button>
                <button className="btn btn-danger" style={{ marginLeft: '11px' }}
                onClick={()=>{
                  deleteAbout(item?._id);
                }}>
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            )
          })
         }
        </tbody>
      </table>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update About Us</h5>
                <button type="button" className="btn-close" onClick={toggleEditModal}></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="edit-image" className="form-label">
                      Upload Image
                    </label>
                    <input type="file" className="form-control" 
                    onChange={(e)=>{setEditAboutusimage(e.target.files[0])}} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="edit-description" className="form-label">
                    </label>
                    <CKEditor
              editor={ClassicEditor}
              data={EditAboutusdesc}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditAboutusdesc(data);
                   }}
                   />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary"
                onClick={editAbout}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
