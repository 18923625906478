import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "@fortawesome/fontawesome-free/css/all.min.css"; // Font Awesome
import axios from "axios";

const Banner = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const toggleAddModal = () => setShowAddModal(!showAddModal);
  const toggleEditModal = () => setShowEditModal(!showEditModal);


  const[Courseimg,setCourseimg]=useState("")
  const[Coursename,setCoursename]=useState("")
  const[Coursedesc,setCoursedesc]=useState("")
 
  const addCourselist = async () => {
    try {
      const formData = new FormData();
      formData.append("courseimg", Courseimg);
      formData.append("coursename", Coursename);
      formData.append("coursedesc", Coursedesc);
  
      const config = {
        url: "/addcourse",
        method: "post",
        baseURL: "https://satechnology.org/api/admin",
        headers: { 
          "Content-Type": "multipart/form-data" // Correct content type for file uploads
        },
        data: formData,
      };
  
      let res = await axios(config);
  
      if (res.data.success) { 
        alert(res.data.success);
        toggleAddModal();
        getCourselist();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };

  const[CourseList,setCourseList]=useState([])
  const getCourselist=async()=>{
     try {
      const getdata=await axios.get(
        "https://satechnology.org/api/admin/getcourse"
      )
      if(getdata){
        setCourseList(getdata.data.getcourse)
      }
     } catch (error) {
      console.log(error);
     }
  }
  useEffect(()=>{
    getCourselist()
  },[])

  const deleteCourselist=async (courseid)=>{
    const confirmed=window.confirm("Are you sure you want to delete this banner?")
    if(confirmed){
    try {
      const deletedata = await axios.delete(
        `https://satechnology.org/api/admin/deletecourse/${courseid}`
      );
      if (deletedata.status === 200) {
        alert(deletedata.data.success);
        getCourselist();
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  }
  }

  const[EditCourseimg,setEditCourseimg]=useState("")
  const[EditCoursename,setEditCoursename]=useState("")
  const[EditCoursedesc,setEditCoursedesc]=useState("")
  const[CourseId,setCourseId]=useState("")
 
  const editCourselist = async () => {
    try {
      const formData = new FormData();
      formData.append("courseimg", EditCourseimg);
      formData.append("coursename", EditCoursename);
      formData.append("coursedesc", EditCoursedesc);
      formData.append("id", CourseId);
  
      const config = {
        url: "/editcourse",
        method: "put",
        baseURL: "https://satechnology.org/api/admin",
        headers: { 
          "Content-Type": "multipart/form-data" // Correct content type for file uploads
        },
        data: formData,
      };
  
      let res = await axios(config);
  
      if (res.data.success) { 
        alert(res.data.success);
        toggleEditModal();
        getCourselist();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };
  return (
    <div className="container mt-2">
      <div
        className="display"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2>This Course will show only on Home Page of website </h2>
        <button
          type="button"
          className="btn btn-primary"
          onClick={toggleAddModal}
        >
          <i className="fas fa-plus"></i> Add
        </button>
      </div>

      {/* Add Modal */}
      {showAddModal && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Banner Us</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleAddModal}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  {/* <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                      Upload Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                    onChange={(e)=>{setCourseimg(e.target.files[0])}}
                    />
                  </div> */}
                  <div className="mb-3">
               <label htmlFor="name" className="form-label">Title</label>
               <input type="text" className="form-control" id="name" placeholder="Enter name" 
               onChange={(e)=>{setCoursename(e.target.value)}} /> 
             </div> 
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      rows="3"
                      placeholder="Enter description"
                      onChange={(e)=>{setCoursedesc(e.target.value)}}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={addCourselist}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Student Table */}
      <table className="table table-bordered mt-2">
        <thead className="table-light">
          <tr>
            <th>S.No</th>
            {/* <th>Image</th> */}
            <th>Course Name</th>
            <th>Description</th>
            <th style={{width:"125px"}}>Action</th>
          </tr>
        </thead>
        <tbody>
        {CourseList?.map((item,i)=>{
          return (
            <tr>
            <td>{++i}</td>
            {/* <td>
              <img
                style={{height:"150px", width:"200px"}}
                src={`https://satechnology.org/CourseList/${item?.courseimg}`}
                alt="courselist"
              />
            </td> */}
            <td>{item?.coursename}</td>
            <td>{item?.coursedesc}</td>
            <td>
              <button className="btn btn-warning" onClick={()=>{
                toggleEditModal();
                setCourseId(item?._id);
                setEditCoursename(item?.coursename)
                setEditCoursedesc(item?.coursedesc)
              }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className="btn btn-danger" style={{ marginLeft: "11px" }}
              onClick={()=>deleteCourselist(item?._id)}>
                <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          )
        })}
        </tbody>
      </table>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Banner Us</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleEditModal}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  {/* <div className="mb-3">
                    <label htmlFor="edit-image" className="form-label">
                      Upload Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                     onChange={(e)=>{setEditCourseimg(e.target.files[0])}}
                    />
                  </div> */}
                  <div className="mb-3">
               <label htmlFor="name" className="form-label">Title</label>
               <input type="text" className="form-control" id="name" placeholder="Enter name"
                value={EditCoursename}
                onChange={(e)=>{setEditCoursename(e.target.value)}} /> 
             </div> 
                  <div className="mb-3">
                    <label htmlFor="edit-description" className="form-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={EditCoursedesc}
                      onChange={(e)=>{setEditCoursedesc(e.target.value)}}
                    >
                 
                    </textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" 
                onClick={editCourselist}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
