import React from 'react'
import '../styles/footer.css'
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <div>
        <div className="footer">
         <div className="container">
           <div className="row pt-5 pb-3 text-start">
            <div className="col-md-8 text-start d-flex justify-content-start ">
                    <a href="#" style={{marginRight:'50px'}}>About Bits Traffic</a>
                    <a href="#contact" style={{marginRight:'50px'}}>Contact us</a>
                    <a href="#carrer">Career</a>
            </div>
            <div className="col-md-4 text-left  ml-8">
            <p><b>Contact :</b><a href="">+91 9779498661</a></p>
            <p><b>Email Id :</b> <a href="">inderjit.randhawa@gmail.com</a></p>
                {/* <a href="#"><FaFacebookSquare style={{fontSize:'24px',marginLeft:'50px'}}/></a>
                <a href="#"><FaInstagramSquare style={{fontSize:'24px',marginLeft:'50px'}}/></a>
                <a href="#"><FaXTwitter style={{fontSize:'24px',marginLeft:'50px'}}/></a> */}
            </div>
           </div>
      <hr />
      <p style={{color:'rgb(117, 110, 110)'}}><small>©2024 Bits Traffic, Inc.</small></p>
         </div>
        </div>
    </div>
  )
}

export default Footer