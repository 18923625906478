import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from "html-react-parser";
const Banner = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const toggleAddModal = () => setShowAddModal(!showAddModal);
  const toggleEditModal = () => setShowEditModal(!showEditModal);

  const[Bannerimage,setBannerimg]=useState("")
  const[Bannertext,setBannertext]=useState("")
 
  const addBanner = async () => {
    try {
      const formData = new FormData();
      formData.append("bannerimage", Bannerimage);
      formData.append("bannertext", Bannertext);
  
      const config = {
        url: "/addbanner",
        method: "post",
        baseURL: "https://satechnology.org/api/admin",
        headers: { 
          "Content-Type": "multipart/form-data"
        },
        data: formData,
      };
  
      let res = await axios(config);
  
      if (res.data.success) { 
        alert(res.data.success);
        toggleAddModal();
        getBanner();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };

  const[Banner,setBanner]=useState([])
  const getBanner=async()=>{
     try {
      const getdata=await axios.get(
        "https://satechnology.org/api/admin/getbanner"
      )
      if(getdata){
        setBanner(getdata.data.allbanner)
      }
     } catch (error) {
      console.log(error);
     }
  }
  useEffect(()=>{
    getBanner()
  },[])

  const deleteBanner=async (bannerid)=>{
    const confirmed=window.confirm("Are you sure you want to delete this banner?")
    if(confirmed){
    try {
      const deletedata = await axios.delete(
        `https://satechnology.org/api/admin/deletebanner/${bannerid}`
      );
      if (deletedata.status === 200) {
        alert(deletedata.data.success);
        getBanner();
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  }
  }

  const[EditBannerimage,setEditBannerimg]=useState("")
  const[EditBannertext,setEditBannertext]=useState("")
  const[BannerId,setBannerId]=useState("")
  
  const editBanner = async () => {
    try {
      const formData = new FormData();
      formData.append("bannerimage", EditBannerimage);
      formData.append("bannertext", EditBannertext);
      formData.append("id", BannerId);
  
      const config = {
        url: "/editbanner",
        method: "put",
        baseURL: "https://satechnology.org/api/admin",
        headers: { 
          "Content-Type": "multipart/form-data" // Correct content type for file uploads
        },
        data: formData,
      };
  
      let res = await axios(config);
  
      if (res.data.success) { // Correct way to access success response
        alert(res.data.success);
        toggleEditModal();
        getBanner();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };
  return (
    <div className="container mt-2">
      <div className="display" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Banner </h2>
        {/* <button type="button" className="btn btn-primary" onClick={toggleAddModal}>
          <i className="fas fa-plus"></i> Add
        </button> */}
      </div>

      {/* Add Modal */}
      {showAddModal && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Banner Us</h5>
                <button type="button" className="btn-close" onClick={toggleAddModal}></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                      Upload Image
                    </label>
                    <input type="file" className="form-control" 
                    onChange={(e)=>setBannerimg(e.target.files[0])}/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label"></label>
                     <CKEditor
              editor={ClassicEditor}
              data={Bannertext}
              onChange={(event, editor) => {
                const data = editor.getData();
               setBannertext(data);
                   }}
                   />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary"
                onClick={addBanner}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Student Table */}
      <table className="table table-bordered mt-2">
        <thead className="table-light">
          <tr>
            <th>S.No</th>
            {/* <th>Image</th> */}
            <th >Description</th>
            <th style={{width:"125px"}}>Action</th>
          </tr>
        </thead>
        <tbody>
         {Banner?.map((item,i)=>{
          return(
            <tr>
            <td>{++i}</td>
            {/* <td>
            <img
                style={{height:"150px", width:"200px"}}
                src={`https://satechnology.org/Banner/${item?.bannerimage}`}
                alt="banner"
              />
            </td> */}
            <td >{parse(item?.bannertext || '')}</td>
            <td>
              <button className="btn btn-warning" onClick={()=>{
                toggleEditModal();
                setBannerId(item?._id);
                setEditBannertext(item?.bannertext);
              }}>
                <i className="fas fa-edit"></i>
              </button>
              {/* <button className="btn btn-danger" style={{ marginLeft: '11px' }}
              onClick={()=>deleteBanner(item?._id)}>
                <i className="fas fa-trash"></i>
              </button> */}
            </td>
          </tr>
          )
         })}
        </tbody>
      </table>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Banner Us</h5>
                <button type="button" className="btn-close" onClick={toggleEditModal}></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="edit-image" className="form-label">
                      Upload Image
                    </label>
                    <input type="file" className="form-control" 
                    onChange={(e)=>setEditBannerimg(e.target.files[0])}/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="edit-description" className="form-label">
                      Description
                    </label>
                    <CKEditor
              editor={ClassicEditor}
              data={EditBannertext}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditBannertext(data);
                   }}
                   />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary"
                onClick={editBanner}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
