import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome
import axios from 'axios';

const FolderList = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const toggleAddModal = () => setShowAddModal(!showAddModal);
  const toggleEditModal = () => setShowEditModal(!showEditModal);

  const [FolderName,setFolderName]=useState("")

  const addFolder = async () => {

    try {
      const config = {
        url: "/addfoldername", 
        method: "post", 
        baseURL: "https://satechnology.org/api/admin",
        headers: { "Content-Type": "application/json" },
        data: {
          foldername:FolderName
        },
      };

      let res = await axios(config); // Make request with axios

      if (res.status === 200) {
        alert(res.data.success);
        toggleAddModal()
        getFoldername()
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  }


  let[Folder,setFolder]=useState([])

  const getFoldername=async ()=>{
    try {
      const getdata=await axios.get("https://satechnology.org/api/admin/getfoldername")
      if(getdata){
        setFolder(getdata.data.folderlist)
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(() => {
    getFoldername()
  }, [])


  const [EditFolderName,setEditFolderName]=useState("")
  const [FolderId,setFolderId]=useState("")
  const editFolder = async () => {
      
    try {
      const config = {
        url: "/editfoldername", 
        method: "put", 
        baseURL: "https://satechnology.org/api/admin",
        headers: { "Content-Type": "application/json" },
        data: {
          id:FolderId?._id,
          foldername:EditFolderName
        },
      };

      let res = await axios(config); // Make request with axios

      if (res.status === 200) {
        alert(res.data.success);
        toggleEditModal()
        getFoldername()
        setEditFolderName("")
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  }


  const deleteFolder=async(textid)=>{
    const confirmed = window.confirm("Are you sure you want to delete this Package Title?");
if(confirmed){
try {
  const deletedata=await axios.delete(
    `https://satechnology.org/api/admin/deletefoldername/${textid}`
  )
  if(deletedata.status===200){
    alert(deletedata.data.success)
    getFoldername();
  }
} catch (error) {
  alert(error.response.data.error)
}
}
  };
  return (
    <div className="container mt-2">
      <div className="display" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Folder List </h2>
        <button type="button" className="btn btn-primary" onClick={toggleAddModal}>
          <i className="fas fa-plus"></i> Add
        </button>
      </div>

      {/* Add Modal */}
      {showAddModal && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Folder List </h5>
                <button type="button" className="btn-close" onClick={toggleAddModal}></button>
              </div>
              <div className="modal-body">
                <form>

                <div class="mb-3">
                  <label for="name" class="form-label">Folder Name</label>
                 <input type="text" class="form-control" id="name" placeholder="Enter title" value={FolderName}
                onChange={(e)=> setFolderName(e.target.value)}/> 
              </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary"
                onClick={addFolder}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Student Table */}
      <table className="table table-bordered mt-2">
        <thead className="table-light">
          <tr>
            <th>S.No</th>
            <th>Folder Name</th>
            <th style={{width:"155px"}}>Action</th>
          </tr>
        </thead>
        <tbody>
          {Folder?.map((item,i)=>{
            return (
              <tr>
            <td>{++i}</td>
        
            <td>{item?.foldername}</td>
 
            <td>
              <button className="btn btn-warning" onClick={()=>{
                toggleEditModal();
                setEditFolderName(item.foldername);
                setFolderId(item);
              }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className="btn btn-danger" style={{marginLeft:"18px" }}
              onClick={() => {deleteFolder(item?._id)}}>
                <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
            )
          })}
        </tbody>
      </table>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update About Us</h5>
                <button type="button" className="btn-close" onClick={toggleEditModal}></button>
              </div>
              <div className="modal-body">
                <form>
                <div class="mb-3">
               <label for="name" class="form-label">Folder Name</label>
               <input type="text" class="form-control" id="name" placeholder="Enter title"
              value={EditFolderName} onChange={(e)=>{setEditFolderName(e.target.value)}}/> 
             </div>
             
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={editFolder}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FolderList;
