import { Image } from '@mui/icons-material';
import axios from 'axios';
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {

  const handleLogin = (e) => {
    e.preventDefault();
  };
  const [adminemail, setEmail] = useState('');
  const [adminpassword, setPassword] = useState('');
  console.log("adminemail",adminemail,"pass",adminpassword);
  
  const navigate = useNavigate();

const handleRedirect = () => {
  navigate("/dashboard");
};
  const adminLogin = async () => {
    try {
      const res = await axios.post('https://satechnology.org/api/admin/adminlogin', { adminemail, adminpassword });
      if(res.status === 200){
        alert(res.data.success);
        sessionStorage.setItem("admin",JSON.stringify(res.data.admin))
        handleRedirect()
      }
    } catch (error) {
      alert(error.response?.data?.error || 'Something went wrong');
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100">
        <Col md={6} lg={4} className="mx-auto">
          <Card>
            <Card.Body>
      
            {/* <div className="text-center mb-4">
                <Image
                  src="/images/bitslogo.jpeg"
                  alt="Logo"
                  className="mb-3"
                  style={{ width: '80px' }}
                  fluid
                />
                <h3>Admin Login</h3>
              </div> */}
              <h3 className="text-center mb-4">Admin Login</h3>
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={adminemail}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={adminpassword}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

               <Button  variant="primary" type="submit" className="w-50" onClick={adminLogin}>       
              Login
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminLogin;
