import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const StudentFolder = () => {
  const location = useLocation();
  const receivedData = location.state;

  let student = JSON.parse(sessionStorage.getItem("student"));

  const [StudentList, setStudentList] = useState([]);
  const getStudentlist = async () => {
    try {
      const getdata = await axios.get(
        "https://satechnology.org/api/admin/getstudentlist"
      );
      if (getdata) {
        setStudentList(getdata.data.studentlist?.filter((ele)=>ele._id === student?._id )?.[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("StudentList",StudentList);
  
  const currentdate = new Date();
  // Function to format the date as "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 because months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  console.log("currentdate", formatDate(currentdate));
 

  const ExpiredCourse = async () => {
    try {
      const config = {
        url: "/expirecourse",
        method: "put",
        baseURL: "https://satechnology.org/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          userId: student?._id,
        },
      };

      const res = await axios(config);
      if (res.status === 200) {
        // alert(res.data.success);
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };

  useEffect(() => {
    const formattedCurrentDate = formatDate(currentdate);
    if(receivedData){
      if (formattedCurrentDate === StudentList?.tilldate) {
        ExpiredCourse();
      }
    }

  }, [currentdate, StudentList]);

  const [Subfolder, setSubfolder] = useState([]);
  const getSubFolder = async () => {
    try {
      const getdata = await axios.get(
        "https://satechnology.org/api/admin/getsubfoldername"
      );
      if (getdata) {
        setSubfolder(
          getdata.data.subfolderlist?.filter((ele)=>ele.foldername === receivedData?.item?.foldername));
      }

      // ?.filter(
      //   (ele) =>
      //     ele.foldername === receivedData?.item?.foldername &&
      //   StudentList?.assign === true
      // )
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubFolder();
    getStudentlist()
  }, []);
  return (
    <div>
      <div className="input-group mt-2 mb-3" style={{ width: "550px" }}>
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          aria-label="Search"
        />
        <span className="input-group-text" style={{ backgroundColor: "green" }}>
          <i className="fa fa-search"></i>
        </span>
      </div>

      <div className="d-flex justify-content-center">
        <h1>{receivedData?.item?.foldername}</h1>
      </div>
      <div className="row">
        <table className="table table-bordered mt-2">
          <thead className="table-light">
            <tr>
              {/* <th>S.No</th> */}
              {/* <th>Subject Name</th> */}
              <th>Episode</th>
              <th>Title</th>
              <th>Pdf</th>
              <th>Video Lecture</th>
            </tr>
          </thead>
          <tbody>
          {/* .filter((ele)=>ele.       StudentList?.assign === true)? */}
            {Subfolder?.filter((ele)=>StudentList?.assign === true)?.map((item, i) => {
              return (
                <tr>
                  {/* <td>{item?.foldername}</td> */}
                  <td>Episode {i + 1}</td>
                  <td>
                    {/* <i className="fa fa-folder-open" /> */}
                    {item?.subfoldername}
                  </td>
                  <td>
                    {" "}
                    <a
                      href={`https://satechnology.org/SubFolder/${item?.pdf}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download/View PDF
                    </a>
                  </td>
                  <td>
                    {" "}
                    {item?.lecture ? (
                      <video width="250" controls>
                        <source
                          src={`https://satechnology.org/SubFolder/${item?.lecture}`}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <>
                        <p>Video lecture not uploaded</p>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {Subfolder?.filter((ele)=>StudentList?.assign === true)?.length ? (
          <></>
        ) : (
          <>
            <div><h2 style={{color:"red"}} >Your Course has been  Expired</h2></div>
          </>
        )}
      </div>
    </div>
  );
};

export default StudentFolder;
